/* @import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,700;1,400;2,200&family=Roboto&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400&display=swap');
:root {
  --primary-blue: #0075FF;
  --background-white: #f7f8fa;
  --dark-title: #30374c;
  --light-title: #626880;
  --light-background: #717982;
  --highlight-red: #ff3b2d;
  --tab-outline: #8d00f9;
  --primary-white: #fefefe;
  --full-white: #ffffff;
  --light-border: #dfe5ff;
  --light-grey: #ced4f0;

}

* {
  font-family: 'outfit' !important;
}

body {
  height: 100%;
}

button {
  text-transform: none !important;
}

button:focus {
  outline: none;
}

.all-content-backdrop {
  background: var(--background-white);
}

@media (min-width: 600px) and (max-width: 2500px) {
  .all-content-backdrop {
    height: calc(100vh - 5.5em);
  }

  .main-content-container {
    margin-top: 3.3em;
    padding-left: 12.3em;
  }
}

@media only screen and (max-width: 600px) {
  .all-content-backdrop {
    height: calc(100vh - 38px);
  }

  .main-content-container {
    margin-top: 3em;
    padding-left: 3%;
  }
}

/* .main-content-container {
  font-size: 1.3rem;
  background-color: var(--background-white);
  color: var(--light-background);
  padding-right: 2%;
  padding-top: 1.15%;
} */

/* .main-content-div {
  width: 100%;
  margin-top: 12px;
} */

.head-title-text {
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 5px;
  color: var(--light-title);
}

.space-between-container {
  display: flex;
  justify-content: space-between;
}

.column-flex-box {
  display: block;
  flex-direction: column;
}
.flex-margin-container {
  display: flex;
  justify-content: center;
  line-height: 5rem;

  

  /* margin-bottom: 2rem; */
}


.align-text-center {
  text-align: center;
}

.pagination-container {
  margin: 15px 0;
}
.flex-center-container {
  display: flex;
  justify-content: center;
}

.space-even-container {
  display: flex;
  justify-content: space-evenly;
}

.rbc-month-header {
  background-color: #cee4ff !important;
  border-collapse: collapse !important;
  border: none !important;
  height: 40px;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  padding-top: 10px;

  /* font-size: medium; */
  box-shadow: 0 5px 5px -5px #333 !important;
  margin-bottom: 1px;
  font-family: MONOSPACE, SansSerif, sans-serif, serif;
}
.font-card-profile{
   font-family: sans-serif;
   
}
.box-shadow
{
  height: 100vh;
  width: 100vh;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); 

}

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%;


}

.rbc-month-row:last-child {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;

}

.rbc-header+.rbc-header {
  border-left: none !important;

}

.rbc-header {
  text-shadow: 0 0 #ddd !important;
  font-size: 15px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 500 !important;
  color: #14204d !important
}

.rbc-toolbar .rbc-toolbar-label {
  font-size: 27px !important;
  text-align: right !important;
}

.rbc-today {
  background-color: #cee4ff !important;
  border: 1px solid blue !important;
}

.rbc-date-cell {

  text-align: center !important;
  font-size: 1rem !important;
}

.rbc-row-bg {
  background-color: white !important;
}

.rbc-month-view {
  position: relative;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  width: 100%;
  user-select: none;
  border-radius: 20px px;
  -webkit-user-select: none;
  height: 100%;
  border-radius: 20px !important;
}

.rbc-off-range-bg {
  background: #eeeeef !important;
}
.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #ffffff !important;
}
.rbc-event {
  padding: 0px !important;
  border: none !important;
  box-sizing: border-box;
  box-shadow: none;
  background-color: white !important;
  border-radius: 0px !important;
  color: rgb(14, 11, 11) !important;
  cursor: pointer;
  width: 100%;
  font-size: 13px;
  text-align: center !important;
  align-items: center !important;
}

.flex-fixed-row{
  display:flex;
  flex-direction: initial;
  justify-content: space-evenly;
  text-align: center;
  position: absolute;
} 
.margin-user-profile{
  margin: 50px;
}

.margin-bottom-form {
  margin-bottom: 15px;
}

.align-container-center {
  margin-right: auto;
  margin-left: auto;

}

.display-avatar-center {
  display: flex;
  justify-content: center;
  align-items: center;

}
.table-align-center{ 
    border: none;
    border-collapse: separate;
    border-spacing: 10rem 0;
    /* column-gap: 0ch; */
}
.profile-logo {
  margin: 10px;
  padding: 10px;
}

.details-profile {
  margin: 20px 40px;
}

.align-container-inline{
  display: flex;
}


.MuiPaper-root .MuiAccordion-root:before {
  background-color: rgba(0, 0, 0, 0)  !important;
}


.rdrCalendarWrapper {
  /* position: absolute; */
  float:right;
  /* z-index: 50; */
  
}

.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: none !important
}

.MuiTableCell-root.MuiTableCell-head {
  font-weight: bold !important;
}








span.displayOverflow > div{
  position: absolute;
  z-index: 50;
}
.page-holder {
    display: flex;
    justify-content: flex-end;
    /* position: fixed; */
}




button {
  text-transform: none !important;
}

.form-internal-body {
  margin-left: 5%;
  margin-right: 5%;
}

button:focus {
  outline: none !important;
}

.all-content-backdrop {
  background: #f0f7fd;
  height: 100vh;
}

.all-content-backdrop.no-overflow{
  overflow: hidden;
}

.main-content-container{
  padding-left: 18.5%;
  font-size: 1.3rem;
  height: "100vh";
  background-color:#f0f7fd;
  /* color: var(--light-background); */
  margin-top: 5%;
  padding-right: 0.5%;
}

.main-content-overlay {
  flex-grow: 1;
  background-color: var(--primary-white);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  margin-top:1.8rem;
  margin-bottom: 2rem;
  border-radius:20px
}

.main-content-div {
  width: 100%;
  margin-top: 1px;
}

.stepper-container {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 30px;
}

.head-title-text {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: var(--light-title);
}

.form-top-grid {
  max-width: 450px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.asterisk-span {
  color: #db3131;
}

.outlined-radius-button {
  border-radius: 12px !important;
  color: var(--primary-blue);
  height: 42px;
  text-align: center;
  margin-top: 20px;
}

.radius-button {
  border-radius: 12px !important;
  color: var(--base-white);
  height: 42px;
}

.form-text {
  padding: 3% 21.5% 0 21.5%;
  color: var(--dark-title);
}

.form-text p {
  color: var(--dark-title);
  font-size: 16px;
}

.center-loader {
  display: flex;
  justify-content: center;
  width: 100%;
}

.forms-button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  margin-top: 15px;
}

.forms-button-container-main {
  display: block;
  margin-bottom: 25px;
  margin-top: 15px;
}

.refresh-button {
  color: var(--highlight-red) !important;
  border: solid 1.5px var(--highlight-red) !important;
}

.refresh-icon-style {
  margin-right: 10px;
  font-size: 12px;
  color: var(--highlight-red);
}

.save-draft-button {
  color: var(--primary-blue) !important;
  border: 1.5px solid var(--primary-blue) !important;
  margin-left: 20px !important;
}

.save-draft-button-contest {
  color: var(--primary-blue) !important;
  border: 1.5px solid var(--primary-blue) !important;
  margin-left: 20px !important;
  margin-top: 10px !important;
  width: 100px;
}

.save-icon-style {
  margin-right: 10px;
  font-size: 12px;
  color: var(--primary-blue);
}

.next-button {
  background-color: var(--primary-blue) !important;
}

.next-icon {
  margin-left: 10px !important;
  font-size: 12px !important;
}

/* .forward-icon {
  margin-right: 10px !important;
  font-size: 1rem !important;
} */

.main-title-divider {
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
}

.review-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  width: 100%;
  height: 68px;
  background: var(--primary-white);
  box-shadow: 0px 2px 8px rgba(206, 212, 240, 0.8);
  border-radius: 16px;
  margin-bottom: 24px;
}

.review-title-text {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: var(--dark-title);
}

.review-buttons-color {
  color: var(--primary-blue) !important;
}

.review-icons {
  margin-right: 10px;
}

.review-divider {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
}

.tab-list-button {
  color: var(--background-white) !important;
  white-space: nowrap;
  border-radius: 12px !important;
  border: 0 !important;
}

.tab-list-button:hover {
  border: 0 !important;
  background-color: transparent;
}

.tab-list--active-button {
  color: var(--background-white);
  white-space: nowrap;
  background-color: var(--background-white) !important;
  border-radius: 12px !important;
}

.step-count-wrapperadmin {
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  padding: 0.7% 1% 0.7% 1% !important;
  font-size: 15px;
  height: 13%;
  background: var(--full-white);
  border: 1.5px solid var(--light-border) !important;
  border-radius: 8px;
}

.step-count-text {
  font-size: 23px;
  color: var(--dark-title);
  margin: 0;
  padding: 0;
}

.step-underline {
  width: 50px;
  height: 2px;
  flex: none;
  order: 2;
  flex-grow: 0;
  border-radius: 16px;
}

.columns-dialog-title {
  font-size: 18px;
  margin-top: 7px;
}

.columns-sub-title {
  font-size: 15px;
}

span::first-letter {
  text-transform: capitalize;
}

.faq-answer {
  background-color: var(--light-white);
  padding-left: 3%;
  border-radius: 8px;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-right: 1%;
}

.head-button-container {
  background-color: var(--primary-blue);
  height: 8.6vh;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 1.2% 3%;
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1px;
  padding-top:1px;
}

.MuiButtonBase-root .MuiPaginationItem-root {
  border: 1px solid var(--light-grey) !important;
}

.grid-container-home {
  height: 70vh;
  width: 100%;
}

.grid-container {
  height: 30vh;
  width: 100%;
}


.grid-select {
  height: 32px;
  font-size: 0.875rem;
}

.toolbar-container {
  display: flex;
  justify-content: space-between;
}

.admin-contest-backdrop {
  padding-left: 17.5em;
  font-size: 1.3rem;
  color: var(--light-background);
  margin-top: 6.8%;
  padding-right: 5%;
}

.generic-icon {
  margin-left: 10px;
  font-size: 18px !important;
  color: var(--primary-blue);
}

.tab-panel {
  width: 100%;
}

.admin-contest-review {
  padding: 1% 5% 4% 5%;
}

.add-team-grid {
  margin-bottom: 16px !important;
}

.add-team-icon {
  margin-right: 8px;
}

.form-body-content {
  margin-top: 9%;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  /* margin-left: 6em;
  margin-right: 6em; */
}

#wel-line {
  height: 6px;
  width: 40px;
  background-color: #4a68d4;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}

.checkbox-right {
  margin-left: 44% !important;
}

.recruiter-icons {
  margin-right: 34% !important;
}

.recruiter-left-image {
  margin-right: 33.5%;
}

.recruiter-top-text {
  margin-bottom: px;
}

.recruiter-bottom-text {
  margin-top: 0.2em;
}

.form-icon-items {
  display: flex;
  justify-content: center;
  padding-left: 30%;
  padding-right: 30%;
  text-align: center;
}

.buttonNext {
  display: flex;
}

.form-div {
  /* background: var(--background-white) !important; */
  /* margin: 0 1rem; */
  /* border-radius: 20px !important; */
  width: 97% !important;
  height: auto;
  box-shadow: none !important;
  /* padding: 10px; */
}

.menu-item-icon-png {
  height: 24px;
  width: 24px;
  position: relative;
  bottom: 8.5px;
}

.self-test-picker{
  margin-bottom: 15px;
}
.modalForm{
  overflow: scroll;
  height: 65vh;
}

.action-buttons-container {
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
  margin-top: 10px;
}

.side-menu-bottom-padding {
  padding-bottom: 15px;
}

.MuiMenu-root .MuiMenuItem-root {
  font-size: 12px;
}

.MuiFormControl-root .MuiSelect-select {
  font-size: 12px;
}

.MuiInputBase-root .MuiInputBase-input {
  font-size: 12px;
}

.spinner{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 9999;
  width: 100vw;
  justify-content: center;
  background-color: rgba(0,0,0,0.5);
}

.batch-form-styling .MuiGrid-root>.MuiGrid-item {
  flex-basis: calc(33% - 20px);
  align-items: flex-end;
  flex-wrap: wrap;
}
.innerbox{
  width: 20%;
  border-radius: 10px;
  height:80px;
  width:100%;

}

.outerbox{
   display: flex;
    flex-wrap: wrap;
     gap:10px;
      height:140px;

}
.fourbox{
  display: flex;
  flex-wrap: wrap;
   gap:10px;
   width: 50%;
    /* height:140px; */
    align-items: center;
}
.innerboxLine {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 0px rgb(155 150 150 / 49%);
  flex-basis: calc(25% - 10px);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  
  justify-content: space-between;
  cursor: pointer;
}

.innerboxLine .header{
  border-bottom: 2px white solid;
}

.innerboxLine.PREMIX{
  background-color: #A4CC90 !important;
  margin: 0px 5px 0px 5px;
}

.innerboxLine.FRK{
  background-color: #9AABD9 !important;
  margin: 0px 5px 0px 5px;
}

.innerboxLine.MILLER{
  background-color: #F5B195 !important;
  margin: 0px 5px 0px 5px;
}

.innerboxLine.WAREHOUSE{
  background-color: #b2b3b2 !important;
  cursor: default;
}

.innerboxLine.SCHOOL{
  background-color: #b2b3b2 !important;
  cursor: default;
}

.innerboxLine.NFPB{
  background-color: #a495bb !important;
  cursor: default;
}


.fourboxLine {
  display: flex;
    flex: 1;
    /* padding: 20px ; */
    padding: 5px;
    justify-content: space-between;
}
.twobox{

    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    height:120px;
    gap:10px;
    width: 50%;
}

.stats{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 10px;
gap:10px;  
}
.statsLine {
  display: flex;
 
}
.textbox{
  padding: 5px;
  color: white;
  display: flex;
  justify-content: space-between;
}
.textboxLine{
  padding: 29px;
  color: rgb(128, 117, 117);
  display: flex;
  justify-content: space-between;
}
.labelbox{
  text-align: center;
}
.dashboard-card-text{
  color:white;
  font-size: large;

}
.dashboard-card-subtext{
  color: white;
  font-size: medium;
  font-weight: 1000;

}
.dashboard-card-text-grey{
  color:rgb(97, 85, 85);
  font-size: large;

}

.MILLER .dashboard-card-text-grey{
    color: #3965C8;

  }
  
 .MILLER  .bottom-div{
    color: #3965C8;
    background-color: #3965C840;

  }


.PREMIX .dashboard-card-text-grey{
    color: #70CAA7;

  } 

.PREMIX.active{
  box-shadow: 0px 2px 1px -1px #70CAA7, 0px 1px 1px 0px #70CAA7, 0px 1px 5px 0px #70CAA7 !important;
  border: 2px solid #70CAA7;
}

.FRK.active{
  box-shadow: 0px 0px 15px 0px rgb(155 150 150 / 49%);
  border: 2px solid #6a707169;
}

.MILLER.active{
  box-shadow: 0px 2px 1px -1px #6f90dd, 0px 1px 1px 0px #6f90dd, 0px 1px 5px 0px #6f90dd !important;
  border: 2px solid #6f90dd;
}

.WAREHOUSE.active{
  box-shadow: 0px 2px 1px -1px rgb(249,214,217), 0px 1px 1px 0px rgb(249,214,217), 0px 1px 10px 0px rgb(249,214,217) !important;
  border: 2px solid rgb(248, 184, 189);
}

.SCHOOL.active{
  box-shadow: 0px 2px 1px -1px rgb(249,214,217), 0px 1px 1px 0px rgb(249,214,217), 0px 1px 10px 0px rgb(249,214,217) !important;
  border: 2px solid rgb(248, 184, 189);
}

.NFPB.active{
  box-shadow: 0px 2px 1px -1px #6f90dd, 0px 1px 1px 0px #6f90dd, 0px 1px 5px 0px #6f90dd !important;
  border: 2px solid #268d62;
}
  
 .PREMIX .bottom-div{
    color: #70CAA7;
    background-color: #70CAA740;
  }


.FRK .dashboard-card-text-grey {
    color: #6A7071;

  }
 .FRK .bottom-div{
    color: #6A7071;
    background-color: #6A707140;
  }
  .border1{
    border: 2px solid brown;
  }
  .clickBorder{
    border: 2px solid rgb(223, 50, 50);
  }
.WAREHOUSE .dashboard-card-text-grey{
  color: #C73D48;

} 
  
.WAREHOUSE  .bottom-div{
    color: #C73D48;
    background-color: #C73D4840;
  }


  .dashboard-card-subtext-grey{
  color: #808B93;
  font-size: small;

}
.bottom-div{
  /* color: white; */
  padding: 5px 5px 5px 30px;
  font-size: large;
  font-weight: 500;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
/* .premix-card{
  box-shadow: 0px 2px 1px -1px rgb(249,214,217), 0px 1px 1px 0px rgb(249,214,217), 0px 1px 3px 0px rgb(249,214,217) !important;
  border: 2px solid rgb(248, 184, 189);
} */

.premix-card{
  box-shadow: 0px 0px 15px 0px rgb(155 150 150 / 49%) !important;
  cursor: pointer;
}

.frk-card{
  box-shadow: 0px 0px 15px 0px rgb(155 150 150 / 49%) !important;
  cursor: pointer;
}

.miller-card{
  box-shadow: 0px 0px 15px 0px rgb(155 150 150 / 49%) !important;
  cursor: pointer;
}

.nfpb-card{
  box-shadow: 0px 0px 15px 0px rgb(155 150 150 / 49%) !important;
  cursor: pointer;
}


.warehouse-card{
  box-shadow: 0px 0px 15px 0px rgb(155 150 150 / 49%) !important;
  cursor: pointer;
}

.school-card{
  box-shadow: 0px 0px 15px 0px rgb(155 150 150 / 49%) !important;
  cursor: pointer;
}


.premix-card{
    color: #dc3545 !important;
}
.premix-card.active{
  box-shadow: 0px 2px 1px -1px rgb(249,214,217), 0px 1px 1px 0px rgb(249,214,217), 0px 1px 10px 0px rgb(249,214,217) !important;
  border: 2px solid rgb(248, 184, 189);
}

.frk-card{
    color: #3b6ad8 !important;
}
.frk-card.active{
  box-shadow: 0px 2px 1px -1px #6f90dd, 0px 1px 1px 0px #6f90dd, 0px 1px 5px 0px #6f90dd !important;
  border: 2px solid #6f90dd;
}

.nfpb-card{
  color: #727c12 !important;
}
.nfpb-card.active{
box-shadow: 0px 2px 1px -1px #56854a, 0px 1px 1px 0px #6f90dd, 0px 1px 5px 0px #bcd81c !important;
border: 2px solid #6f90dd;
}

.miller-card{
    color: #5ddbab !important;
}
.miller-card.active{
  box-shadow: 0px 2px 1px -1px #70CAA7, 0px 1px 1px 0px #70CAA7, 0px 1px 5px 0px #70CAA7 !important;
  border: 2px solid #70CAA7;
}

.warehouse-card{
    color: #6A7071 !important;
}
.warehouse-card.active{
  box-shadow: 0px 0px 15px 0px rgb(155 150 150 / 49%);
  border: 2px solid #6a707169;
}

.school-card{
  color: #6A7071 !important;
}
.school-card.active{
box-shadow: 0px 0px 15px 0px rgb(155 150 150 / 49%);
border: 2px solid #6a707169;
}

.MuiSwitch-track{
  background-color: #0075ff !important;

}
.MuiSwitch-switchBase{
  color: #0077ffe0 !important;

}
.master-data-block{
  min-height:calc(90vh);
}
.flex-basis-25{
  flex-basis: calc(25% - 20px);
}
.flex-basis-20{
  flex-basis: calc(20% - 15px);
}
.flex-1{
  flex: 1;
}

.hoverLink:hover {
  text-decoration: underline;
}