#batch-detail-form .save-button-div-disabled {
    display: none;
}

#batch-detail-form .save-button-div {
    position: absolute;
    bottom: 15px;
    margin: 0 40%;
}

.batch-form-styling .MuiGrid-root>.MuiGrid-item {
    flex-basis: calc(33% - 20px);
    align-items: flex-end;
    flex-wrap: wrap;
  }

.custom-date-field .MuiFormControl-root { 
    width: 100%;
}
.user-details-form {
    flex-grow: 1;
    background-color: var(--primary-white);
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    margin-top:1.8rem;
    margin-bottom: 2rem;
    border-radius:20px
  }